import React, { Component } from "react";
import "url-search-params-polyfill";
import { Switch, Route, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./Root.scss";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import PageLayout from "../../components/PageLayout/PageLayout";

import banner from "../../assets/images/banner.jpg";

import homepageScreenshot from "../../assets/images/metaImages/generic-preview.png";
import compareVehicleScreenshot from "../../assets/images/metaImages/compare-vehicle-screenshot.png";
import evCatalogScreenshot from "../../assets/images/metaImages/ev-catalog-screenshot.png";
import evPageScreenshot from "../../assets/images/metaImages/ev-page-screenshot.png";
import homeChargerScreenshot from "../../assets/images/metaImages/home-charger-screenshot.png";
import incentivesScreenshot from "../../assets/images/metaImages/incentives-screenshot.png";
import mapScreenshot from "../../assets/images/metaImages/map-screenshot.png";
import faqScreenshot from "../../assets/images/metaImages/faq-screenshot.png";
import costVsRangeScreenshot from "../../assets/images/metaImages/cost-vs-range-screenshot.png";
import eventsScreenshot from "../../assets/images/metaImages/faq-screenshot.png";

// Pages
import Home from "../../components/HomePage/Homepage";
import EVs from "../../../client_customizations/components/EVs/EVs";
import EVsGraph from "../../../pages/EVs/EVsGraph";
import EV from "../../../pages/EV/EV";
import UsedEV from "../../../pages/UsedEV/UsedEV";
import UsedEVs from "../../../client_customizations/components/UsedEVs/UsedEVs";
import Incentives from "../../../pages/Incentives/Incentives";
import MapPage from "../../../pages/MapPage/MapPage";
import ElectricianMapPage from "../../../pages/ElectricianMapPage/ElectricianMapPage";
import DealerMapPage from "../../../pages/DealerMapPage/DealerMapPage";
import FAQ from "../../../pages/FAQ/FAQ";
import FourOhFour from "../../../pages/FourOhFour/FourOhFour";
import CompareVehicles from "../../../components/CompareVehicles/CompareVehicles";
import HomeChargers from "../../../pages/HomeChargers/HomeChargers";
import Events from "../../../pages/Events/Events";
import RateComparison from "../../pages/RateComparison/RateComparison";

// Services
import fetchElectricVehicles from "../../../services/fetchElectricVehicles";
import fetchGasolineVehicles from "../../../services/fetchGasolineVehicles";
import fetchIncentives from "../../../services/fetchIncentives";
import fetchVehicleIncentivesWithHandle from "../../../services/fetchVehicleIncentivesWithHandle";
import fetchHomeChargers from "../../../services/fetchHomeChargers";

// Utilities
import Uuid from "../../../utils/Uuid/Uuid";
import { loadState, persistState } from "../../../utils/LocalStorage/LocalStorage";
import loadUserPrefs from "../../../context/UserPrefs/loadUserPrefs";
import getUserPref from "../../../context/UserPrefs/getUserPref";
import USER_PREF_PRESETS from "../../../context/UserPrefs/USER_PREF_PRESETS";
import GaTracker from "../../../utils/GaTracker/GaTracker";
import isIE from "../../../utils/isIE";
import { MAP_TABS } from "../../../constants/mapTabs";

// Constants
import { mountain, desert, inland } from "../../constants/climateZonesMap";
import { COASTAL, DESERT, INLAND, MOUNTAIN } from "../../constants/chargingClimateZones";

class Root extends Component {
     constructor(props) {
          super(props);
          const existingState = loadState() || {};
          const savedPrefs = existingState && existingState.userPreferences ? existingState.userPreferences : {};

          if (!savedPrefs.vehicleFormFactorFilters) savedPrefs.vehicleFormFactorFilters = {};
          if (!savedPrefs.vehicleFuelTypeFilters) savedPrefs.vehicleFuelTypeFilters = {};
          if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};
          if (!savedPrefs.chargerFormFactorFilters) savedPrefs.chargerFormFactorFilters = {};
          if (!savedPrefs.chargerWifiFilter) savedPrefs.chargerWifiFilter = {};
          if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};

          this.state = {
               uuid: existingState.uuid || Uuid(),
               ipData: existingState.ipData || null,
               electricVehicles: null,
               usedElectricVehicles: null,
               gasolineVehicles: null,
               incentives: null,
               incentivePrefsModalIsOpen: false,
               userLocation: null,
               userLocationNotFound: false,
               userLocationDealersNotFound: false,
               zipcodeUpdating: false,
               homeChargers: null,

               userPreferences: loadUserPrefs(savedPrefs),
          };

          // Last resort to ensure that the user has a UUID
          if (!this.state.uuid) this.state.uuid = Uuid();

          this.loadElectricVehicleData = this.loadElectricVehicleData.bind(this);
          this.loadGasolineVehicleData = this.loadGasolineVehicleData.bind(this);
          this.loadIncentivesData = this.loadIncentivesData.bind(this);
          this.updateUserPreferences = this.updateUserPreferences.bind(this);
          this.getUserZip = this.getUserZip.bind(this);
          this.incentivePrefsModalToggle = this.incentivePrefsModalToggle.bind(this);
          this.loadDealers = this.loadDealers.bind(this);
     }

     componentDidMount() {
          const currentPath = this.props.location.pathname;
          if (currentPath === "/savingscalculator") {
               this.loadElectricVehicleData();
               this.loadGasolineVehicleData();
          } else {
               this.loadElectricVehicleData();
               this.loadGasolineVehicleData();
               this.loadZipcodeData();
               this.loadIncentivesData();
               this.loadDealers();
               this.loadHomeChargers();
               this.getUserZip();
          }

          GaTracker.initialize();
          const page = this.props.location.pathname + this.props.location.search;
          GaTracker.trackPage(page, { userId: this.state.uuid });

          //     this.getUserZip();
     }

     // TODO: this should be cleaner
     componentDidUpdate(prevProps, prevState, snapshot) {
          if (
               this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode ||
               this.state.userPreferences.householdSize !== prevState.userPreferences.householdSize ||
               this.state.userPreferences.householdIncome !== prevState.userPreferences.householdIncome ||
               this.state.userPreferences.canTurnInClunker !== prevState.userPreferences.canTurnInClunker ||
               this.state.userPreferences.taxFilingStatus !== prevState.userPreferences.taxFilingStatus ||
               this.state.userPreferences.vehicleStatusFilter !== prevState.userPreferences.vehicleStatusFilter
          ) {
               this.loadElectricVehicleData();
               this.loadIncentivesData();
          } else if (
               this.state.userPreferences.vehicleIdForIncentives !== prevState.userPreferences.vehicleIdForIncentives ||
               this.state.userPreferences.vehicleHandleForIncentives !==
                    prevState.userPreferences.vehicleHandleForIncentives ||
               this.state.userPreferences.electricityProvider !== prevState.userPreferences.electricityProvider
          ) {
               this.loadIncentivesData();
          }

          if (this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode) {
               this.loadZipcodeData();
               this.loadDealers();
          }

          let prevZip = prevState.userLocation ? prevState.userLocation.postcode : null;
          if (this.state.userLocation && this.state.userLocation.postcode !== prevZip) {
               this.loadDealers();
          }

          if (!this.state.userLocationNotFound && !this.state.zipcodeUpdating) {
               persistState(this.state);
          }

          const currentPage = prevProps.location.pathname + prevProps.location.search;
          const nextPage = this.props.location.pathname + this.props.location.search;

          if (currentPage !== nextPage) {
               GaTracker.trackPage(nextPage, { userId: this.state.uuid });
          }

          if (this.props.language !== prevProps.language) {
               this.loadIncentivesData();
               this.loadElectricVehicleData();
          }

          if (
               this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode ||
               this.state.userPreferences.chargerWifiFilter !== prevState.userPreferences.chargerWifiFilter
          ) {
               this.loadHomeChargers();
          }
     }

     getUserZip() {
          let target = "post-code";
          var match = document.cookie.match(new RegExp("(^| )" + target + "=([^;]+)"));
          if (match) {
               let actualZC = match[2];
               let climateZone = COASTAL;

               if (mountain.includes(Number(actualZC))) {
                    climateZone = MOUNTAIN;
               }

               if (desert.includes(Number(actualZC))) {
                    climateZone = DESERT;
               }

               if (inland.includes(Number(actualZC))) {
                    climateZone = INLAND;
               }

               return setTimeout(
                    this.updateUserPreferences({
                         zipcode: match[2],
                         currentRateClimateZone: climateZone,
                    }),
                    300
               );
          }
     }

     async loadElectricVehicleData() {
          let params = {
               postcode: getUserPref("zipcode", this.state.userPreferences),
               household_size: getUserPref("householdSize", this.state.userPreferences),
               household_income: getUserPref("householdIncome", this.state.userPreferences),
          };

          try {
               const electricVehicles = await fetchElectricVehicles(params);
               if (!electricVehicles) return;
               this.setState({
                    electricVehicles: electricVehicles.newElectricVehicles,
                    usedElectricVehicles: electricVehicles.usedElectricVehicles,
               });
          } catch (e) {
               // TODO: handle errors here
          }
     }

     async loadGasolineVehicleData() {
          let params = {
               fuel_type: "gas",
               postcode: getUserPref("workingZipcode", this.state.userPreferences),
          };

          try {
               const gasolineVehicles = await fetchGasolineVehicles(params);
               if (!gasolineVehicles) return;
               this.setState({ gasolineVehicles });
          } catch (e) {}
     }

     async loadIncentivesData() {
          let params = {
               postcode: getUserPref("zipcode", this.state.userPreferences),
               vehicle_handle: getUserPref("vehicleHandleForIncentives", this.state.userPreferences),
               household_size: getUserPref("householdSize", this.state.userPreferences),
               household_income: getUserPref("householdIncome", this.state.userPreferences),
               turn_in_clunker: getUserPref("canTurnInClunker", this.state.userPreferences) ? true : null,
               tax_filing_type: getUserPref("taxFilingStatus", this.state.userPreferences),
               lang: this.props.language === "EN" ? null : this.props.language,
          };

          if (getUserPref("electricityProvider", this.state.userPreferences) !== "All") {
               params.grantor = getUserPref("electricityProvider", this.state.userPreferences);
          }

          if (this.state.userPreferences.vehicleStatusFilter === "used") {
               params.include_used_vehicles = "true";
               params.treat_as = "used";
          }

          if (params["vehicle_handle"] === "Volkswagen_ID.4_AWD_Pro_BEV_2024") {
               params.include_used_vehicles = "true";
               params.treat_as = "used";
          }

          try {
               let incentives =
                    params["vehicle_handle"] !== ""
                         ? await fetchVehicleIncentivesWithHandle(params)
                         : await fetchIncentives(params);
               if (!incentives) return;
               if (params.include_used_vehicles === "true") {
                    incentives = incentives.filter((incentive) => incentive.applicable_to_used_vehicles === true);
               }
               console.log(incentives);
               this.setState({ incentives });
          } catch (e) {
               // TODO: handle error
          }
     }

     async loadHomeChargers() {
          let params = {
               postcode: getUserPref("zipcode", this.state.userPreferences),
          };

          const wifiPrefs = getUserPref("chargerWifiFilter", this.state.userPreferences);
          if (wifiPrefs.yes) {
               params.wifi = "true";
          } else if (wifiPrefs.no) {
               params.wifi = "false";
          }

          try {
               const homeChargers = await fetchHomeChargers(params);
               if (!homeChargers) return;
               this.setState({ homeChargers });
          } catch (e) {}
     }

     loadDealers() {
          if (!process.env.REACT_APP_PAGES_DEALERS_ENABLED) {
               return;
          }

          const params = {
               postcode: getUserPref("zipcode", this.state.userPreferences),
               distance: isIE() ? 25 : 100,
          };
          let url = new URL(`${process.env.REACT_APP_EV_INFO_API_HOST}/dealers`);

          let searchParams = new URLSearchParams(params);

          url.search = searchParams;
          window
               .fetch(url, {
                    method: "GET",
                    headers: {
                         "Content-Type": "application/json",
                         Accept: "application/json",
                         Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
                    },
               })
               .then((response) => {
                    if (response.status === 400) {
                         this.setState({
                              userLocationDealersNotFound: true,
                         });
                    }
                    return response;
               })
               .then((response) => response.json())
               .then((data) => {
                    if (data.dealers) {
                         this.setState({
                              dealerLocations: data.dealers,
                              userLocationDealersNotFound: false,
                         });
                    }
               });
     }

     loadZipcodeData() {
          const params = {
               postcode: getUserPref("zipcode", this.state.userPreferences),
               distance: 0,
          };
          let url = new URL(`${process.env.REACT_APP_EV_INFO_API_HOST}/location`);

          let searchParams = new URLSearchParams(params);

          url.search = searchParams;

          window
               .fetch(url, {
                    method: "GET",
                    headers: {
                         "Content-Type": "application/json",
                         Accept: "application/json",
                         Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
                    },
               })
               .then((response) => {
                    if (response.status === 400) {
                         this.setState({
                              userLocationNotFound: true,
                              zipcodeUpdating: false,
                         });
                    }
                    return response;
               })
               .then((response) => response.json())
               .then((data) => {
                    if (data.location) {
                         this.setState({
                              userLocation: data.location,
                              userLocationNotFound: false,
                              zipcodeUpdating: false,
                         });
                         let newPrefs = {
                              country: data?.location?.country,
                              municipality: data?.location?.region,
                              salesTax: data?.location?.regional_financial_references?.[0]?.sales_tax?.region / 100.0,
                              gasolinePriceInCentsPerGal: data?.location?.regional_fuel_cost[0]?.gasoline
                                   ? (data.location.regional_fuel_cost[0].gasoline * 100).toFixed(0)
                                   : this.state.userPreferences.gasolinePriceInCentsPerGal,
                         };
                         process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE &&
                              (newPrefs.electricityRate = data?.location?.regional_fuel_cost[0]?.electricity
                                   ? (data.location.regional_fuel_cost[0]?.electricity / 100).toFixed(4)
                                   : this.state.userPreferences.electricityRate);
                         this.updateUserPreferences(newPrefs);
                         this.incentivePrefsModalToggle(false);
                    }
               });
     }

     updateUserPreferences(newPrefs) {
          let prefs = Object.assign({}, this.state.userPreferences, newPrefs);
          let newState = {
               userPreferences: prefs,
          };
          if (newPrefs.zipcode && this.state.userPreferences.zipcode !== newPrefs.zipcode) {
               newState.zipcodeUpdating = true;
          }
          this.setState(newState);
     }

     incentivePrefsModalToggle(override) {
          if (typeof override === "boolean") {
               this.setState({ incentivePrefsModalIsOpen: override });
          } else {
               this.setState({
                    incentivePrefsModalIsOpen: !this.state.incentivePrefsModalIsOpen,
               });
          }
     }

     render() {
          const ip = this.state.ipData ? this.state.ipData.ip : null;
          const uuid = this.state.uuid;
          const language = this.props.language;
          const changeLanguage = this.props.changeLanguage;

          const userPrefs = {
               get: (key) => getUserPref(key, this.state.userPreferences),
               getPreset: (key) => USER_PREF_PRESETS[key],
               set: this.updateUserPreferences,
               zipcodeIsNotFound: this.state.userLocationNotFound,
               zipcodeIsUpdating: this.state.zipcodeUpdating,
               showIncentivePrefsModal: this.state.incentivePrefsModalIsOpen,
               toggleIncentivePrefsModal: this.incentivePrefsModalToggle,
               syncWorkingZipcode: () => {
                    let actualZC = getUserPref("workingZipcode", this.state.userPreferences);
                    let climateZone = COASTAL;

                    if (mountain.includes(Number(actualZC))) {
                         climateZone = MOUNTAIN;
                    }

                    if (desert.includes(Number(actualZC))) {
                         climateZone = DESERT;
                    }

                    if (inland.includes(Number(actualZC))) {
                         climateZone = INLAND;
                    }

                    this.updateUserPreferences({
                         zipcode: actualZC,
                         currentRateClimateZone: climateZone,
                    });
               },
          };

          return (
               <UserPrefsContext.Provider value={userPrefs}>
                    <Switch>
                         <Route
                              exact
                              path="/"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             description="Learn more about electric vehicles (EVs) incentives"
                                             disclaimers="EV Savings Calculator provides illustrative estimations throughout and does not guarantee the accuracy of any costs, savings, hardware specifications, or incentives estimates. Please refer to referenced sources and original equipment manufacturers for up to date costs, hardware specifications, and incentives information."
                                             page="home"
                                             title="Drive Electric with SDG&E | Home"
                                             image={homepageScreenshot}
                                        >
                                             <Home
                                                  {...props}
                                                  electricVehicles={this.state.electricVehicles}
                                                  incentives={this.state.incentives}
                                                  userPreferences={this.state.userPreferences}
                                                  userLocation={this.state.userLocation}
                                                  homePageBannerImage={banner}
                                                  ip={ip}
                                                  uuid={uuid}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />
                         {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
                              <Route
                                   exact
                                   path="/faq"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  props={props}
                                                  ip={ip}
                                                  uuid={uuid}
                                                  description="Answers to Frequently Asked Questions about electric vehicles (EVs) and their incentives"
                                                  page="faq"
                                                  title={process.env.REACT_APP_PAGES_FAQ_TITLE}
                                                  image={faqScreenshot}
                                             >
                                                  <FAQ ip={ip} uuid={uuid} />;
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (
                              <Route
                                   exact
                                   path="/vehicles"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  ip={ip}
                                                  uuid={uuid}
                                                  description=""
                                                  page="vehicles"
                                                  title={"Drive Electric with SDG&E | Vehicle Catalog"}
                                                  image={evCatalogScreenshot}
                                             >
                                                  <EVs
                                                       {...props}
                                                       electricVehicles={this.state.electricVehicles}
                                                       incentives={this.state.incentives}
                                                       location={this.state.userLocation}
                                                       ip={ip}
                                                       uuid={uuid}
                                                  />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
                              <Route
                                   exact
                                   path="/used-vehicles"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  page="vehicles"
                                                  ip={ip}
                                                  e
                                                  uuid={uuid}
                                                  title={"Drive Electric with SDG&E | Vehicle Catalog"}
                                                  image={evCatalogScreenshot}
                                             >
                                                  <UsedEVs
                                                       {...props}
                                                       usedElectricVehicles={this.state.usedElectricVehicles}
                                                       incentives={this.state.incentives}
                                                       location={this.state.userLocation}
                                                       ip={ip}
                                                       uuid={uuid}
                                                  />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
                              <Route
                                   path="/used-vehicles/:evId"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  page="vehicles"
                                                  ip={ip}
                                                  uuid={uuid}
                                                  disclaimers=""
                                                  title={"Drive Electric with SDG&E | Vehicle Catalog"}
                                                  image={evPageScreenshot}
                                             >
                                                  <UsedEV
                                                       {...props}
                                                       electricVehicles={this.state.usedElectricVehicles}
                                                       userLocation={this.state.userLocation}
                                                       ip={ip}
                                                       uuid={uuid}
                                                  />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (
                              <Route
                                   exact
                                   path="/incentives"
                                   render={(props) => (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="incentives"
                                             description=""
                                             disclaimers="Listed incentives may not be available at any given time. Listed incentives reflect an illustrative estimation of available incentives. {process.env.REACT_APP_FULL_COMPANY_NAME} does not recommend or endorse any particular automotive or insurance company."
                                             title="Drive Electric with SDG&E | Incentives"
                                             image={incentivesScreenshot}
                                        >
                                             <Incentives
                                                  {...props}
                                                  electricVehicles={this.state.electricVehicles}
                                                  incentives={this.state.incentives}
                                                  location={this.state.userLocation}
                                                  ip={ip}
                                                  uuid={uuid}
                                             />
                                        </PageLayout>
                                   )}
                              />
                         ) : null}
                         {MAP_TABS.filter((tab) => tab.isEnabled).map((tab) => {
                              return (
                                   <Route
                                        exact
                                        key={tab.id}
                                        path={tab.url}
                                        render={(props) => {
                                             return (
                                                  <PageLayout
                                                       language={language}
                                                       changeLanguage={changeLanguage}
                                                       props={props}
                                                       ip={ip}
                                                       uuid={uuid}
                                                       page="map"
                                                       description={tab.description}
                                                       title={"Drive Electric with SDG&E | Maps"}
                                                       image={mapScreenshot}
                                                  >
                                                       <MapPage
                                                            {...props}
                                                            tabId={tab.id}
                                                            userLocation={this.state.userLocation}
                                                            ip={ip}
                                                            uuid={uuid}
                                                            dealerLocations={this.state.dealerLocations}
                                                            title={tab.title}
                                                            zipcode={userPrefs.get("zipcode")}
                                                       />
                                                  </PageLayout>
                                             );
                                        }}
                                   />
                              );
                         })}
                         <Route
                              exact
                              key="tabbed-maps-electricians-tab"
                              path="/electricians"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="map"
                                             description={"tab.description"}
                                             title={"Drive Electric with SDG&E | Electrician Finder"}
                                             image={mapScreenshot}
                                        >
                                             <ElectricianMapPage
                                                  {...props}
                                                  tabId={"tabbed-maps-electricians-tab"}
                                                  userLocation={this.state.userLocation}
                                                  ip={ip}
                                                  uuid={uuid}
                                                  dealerLocations={this.state.dealerLocations}
                                                  title={"Drive Electric with SDG&E | Electrician Finder"}
                                                  zipcode={userPrefs.get("zipcode")}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              key="tabbed-maps-dealer-catalog-tab"
                              path="/dealers"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="tabbed-maps-dealer-catalog-tab"
                                             description={"tab.description"}
                                             title={"Drive Electric with SDG&E | Dealer Finder"}
                                             image={mapScreenshot}
                                        >
                                             <DealerMapPage
                                                  {...props}
                                                  tabId={"tabbed-maps-dealer-catalog-tab"}
                                                  userLocation={this.state.userLocation}
                                                  ip={ip}
                                                  uuid={uuid}
                                                  dealerLocations={this.state.dealerLocations}
                                                  title={"Drive Electric with SDG&E | Dealer Finder"}
                                                  zipcode={userPrefs.get("zipcode")}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />
                         {process.env.REACT_APP_PAGES_VEHICLES_MODEL_ENABLED ? (
                              <Route
                                   path="/vehicles/:evId"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  page="vehicles"
                                                  ip={ip}
                                                  uuid={uuid}
                                                  description="Compare Electric Vehicles (EVs) by price, range and features."
                                                  disclaimers=""
                                                  title={"Drive Electric with SDG&E | Vehicle Catalog"}
                                                  image={evPageScreenshot}
                                             >
                                                  <EV
                                                       {...props}
                                                       electricVehicles={this.state.electricVehicles}
                                                       userLocation={this.state.userLocation}
                                                       ip={ip}
                                                       uuid={uuid}
                                                  />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (
                              <Route
                                   exact
                                   path="/compare-vehicles"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  page="compare"
                                                  ip={ip}
                                                  uuid={uuid}
                                                  title="Drive Electric with SDG&E | Compare Vehicles"
                                                  image={compareVehicleScreenshot}
                                             >
                                                  <CompareVehicles
                                                       props={props}
                                                       ip={this.state.ipData ? this.state.ipData.ip : null}
                                                       uuid={this.state.uuid}
                                                       electricVehicles={this.state.electricVehicles}
                                                       gasolineVehicles={this.state.gasolineVehicles}
                                                  />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_COMPARISON_GRAPH_ENABLED ? (
                              <Route
                                   exact
                                   path="/comparison-graph"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  page="cost vs range"
                                                  ip={ip}
                                                  uuid={uuid}
                                                  title={process.env.REACT_APP_PAGES_COMPARISON_GRAPH_TITLE}
                                                  image={costVsRangeScreenshot}
                                             >
                                                  <EVsGraph
                                                       {...props}
                                                       electricVehicles={this.state.electricVehicles}
                                                       ip={ip}
                                                       uuid={uuid}
                                                       displayGraph={true}
                                                  />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (
                              <Route
                                   exact
                                   path="/home-chargers"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  ip={ip}
                                                  uuid={uuid}
                                                  page="home chargers"
                                                  title={"Drive Electric with SDG&E | Home Charging Catalog"}
                                                  image={homeChargerScreenshot}
                                             >
                                                  <HomeChargers
                                                       {...props}
                                                       homeChargers={this.state.homeChargers}
                                                       electricVehicles={this.state.electricVehicles}
                                                       ip={ip}
                                                       uuid={uuid}
                                                  />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_EVENTS_ENABLED ? (
                              <Route
                                   exact
                                   path="/events"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  ip={ip}
                                                  uuid={uuid}
                                                  page="events"
                                                  title={process.env.REACT_APP_PAGES_EVENTS_TITLE}
                                                  image={eventsScreenshot}
                                             >
                                                  <Events {...props} ip={ip} uuid={uuid} />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         <Route
                              exact
                              path="/savingscalculator"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="rates"
                                             title="Drive Electric with SDG&E | Rates"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <RateComparison
                                                  history={props.history}
                                                  electricVehicles={this.state.electricVehicles}
                                                  usedElectricVehicles={this.state.usedElectricVehicles}
                                                  userLocation={this.state.userLocation}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route component={FourOhFour} />
                    </Switch>
               </UserPrefsContext.Provider>
          );
     }
}

export default withRouter(Root);
