import React, { useContext } from "react";
import PropTypes from "prop-types";

import SelectVehicleStatusFilter from "../../../components/InputComponents/SelectVehicleStatusFilter/SelectVehicleStatusFilter";
import SelectVehicleMakeFilter from "../../../components/InputComponents/SelectVehicleMakeFilter/SelectVehicleMakeFilter";
import SelectVehicleModelFilter from "../InputComponents/SelectVehicleModelFilter/SelectVehicleModelFilter";
import SelectVehicleTrimFilter from "../InputComponents/SelectVehicleTrimFilter/SelectVehicleTrimFilter";

import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormattedMessage } from "react-intl";

import "./IncentiveVehicleOptions.scss";

const IncentiveVehicleOptions = ({ electricVehicles }) => {
  const userPrefs = useContext(UserPrefsContext);
  return (
    <div className="input-well MatchScoreOptions">
      <p className="h2">
      <FormattedMessage
            id="evfilter.carTitle"
            defaultMessage="EV Preference"
            description="EV Preference"
          />
      </p>
      <SelectVehicleStatusFilter />
      <SelectVehicleMakeFilter vehicles={electricVehicles} />
      <SelectVehicleModelFilter
        vehicles={electricVehicles}
        makeOption={userPrefs.get("vehicleMakeFilter")}
      />
      <SelectVehicleTrimFilter
        vehicles={electricVehicles}
        makeOption={userPrefs.get("vehicleMakeFilter")}
        modelOption={userPrefs.get("vehicleModelFilter")}
      />
    </div>
  );
};

export default IncentiveVehicleOptions;

IncentiveVehicleOptions.propTypes = {
  electricVehicles: PropTypes.array,
};
